import React, { useEffect, useState } from 'react'
import './Slider.css'
import { TfiLayoutSliderAlt } from "react-icons/tfi";
import { MdOutlineLibraryAdd } from "react-icons/md";
import axios from 'axios';
import { API, FILE_SERVER_URL } from '../../../constant';
import { toast } from 'react-toastify';
const Slider = () => {

	const [sliderFormVisible, setSliderFormVisible] = useState(false);
	const [sliderData, setsliderData] = useState([]);
	const [sliderItemData, setsliderItemData] = useState({
		eventname: "",
		description: "",
		image: "",
	});


	function getBase64(e, cb) {
		const file = e.target.files[0];
		setsliderItemData(p => ({
			...p,
			image: file
		}));
		console.log(file);
	}

	const onChangeInput = (e) => {
		const { name, value } = e.target;
		setsliderItemData(p => ({
			...p,
			[name]: value
		}))
	}

	useEffect(() => {
		axios.get(`${API}/admin/banners`)
			.then((res) => {
				console.log(res);
				const { banners } = res.data;
				setsliderData(banners);
			})
			.catch(e => {
				console.log(e);
			})
	}, [])

	const handleSubmit = (e) => {
		e.preventDefault();
		const formdata = new FormData();
		formdata.append("eventname", sliderItemData?.eventname)
		formdata.append("description", sliderItemData?.description)
		formdata.append("image", sliderItemData?.image)

		axios.post(`${API}/admin/banner/add`, formdata)
			.then(res => {
				console.log(res);
				setsliderData(p => [
					...p,
					res.data.data
				])
				setSliderFormVisible(false)
				setsliderItemData({})
			})
			.catch((e) => {
				console.log(e);
			})
	}

	const deleteBanner = async (id) => {
		try {
			const res = await axios.delete(`${API}/admin/banner/delete/${id}`);
			const { status } = res.data;
			if (status === "success") {
				toast.success("Banner deleted successfuly")
				setsliderData(p => {
					const filterSlider = p.filter((i) => String(i._id) !== String(id))
					return filterSlider;
				})
			}
		} catch (error) {
			console.log(error);
		}
	}


	return (
		<div className="admin-slider-page">
			<div className="header">
				<h4>Slider</h4>
				<p ><TfiLayoutSliderAlt />Slider</p>
			</div>
			<div className="slider-data">
				<div className="responsiveTable ">
					<div className='Slider-nav'>
						<div className="buttonslide" onClick={() => setSliderFormVisible(true)}>
							<MdOutlineLibraryAdd /> <h5>Add slide</h5>
						</div>
					</div>
					<table className="styled-table striped AdminslidershipTable">

						<thead>
							<tr>
								<th>Sr No</th>
								<th>Discription</th>
								<th>Event Name</th>
								<th>Image Name</th>
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							{sliderData.map((sliderItem, idx) => (
								<tr>
									<td>{idx + 1}</td>
									<td>{sliderItem.eventname}</td>
									<td>{sliderItem.description}</td>
									<td>
										<a className='image_container_view'
											style={{
												"--background": `url('${FILE_SERVER_URL}/${sliderItem?.image_path}')`
											}}
											data-image={`url('${FILE_SERVER_URL}/${sliderItem?.image_path}')`} href={`${FILE_SERVER_URL}/${sliderItem?.image_path}`} target='_blank'>{sliderItem.image_name}</a>
									</td>
									<td>
										<button className='Slide-delete' type='button' onClick={() => deleteBanner(sliderItem?._id)}>Delete</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>

			{
				sliderFormVisible &&
				<div className="slideadd">
					<form action="" id='slidepopup' onSubmit={handleSubmit} className='slide-form'>
						<h3>Add Slide</h3>
						<div className='AMFSliderInputGroup'>
							<label htmlFor="eventname">Event Name:</label>
							<input type="text" name='eventname' id='eventname' onChange={onChangeInput} value={sliderItemData.eventname} />
						</div>
						<div className='AMFSliderInputGroup'>
							<label htmlFor="description">Description:</label>
							<input type="text" name='description' id='description' onChange={onChangeInput} value={sliderItemData.description} />
						</div>

						<div className='AMFSliderInputGroup'>
							<label htmlFor="imagefile">Image-Slide:</label>
							<input type="file" name='imagefile' id='imagefile'
								value={sliderItemData.imagefile}
								onChange={getBase64}

							/>
						</div>
						<br />
						<div className="slide-buttons">
							<button className='AMFButton md' onClick={() => setSliderFormVisible(p => !p)}>Close</button>
							<button className='AMFButton md'>Add Slide</button>
						</div>
					</form>
				</div>
			}
		</div>
	)
}

export default Slider