import React from 'react'
import './Objective.css'

import antiCrime from '../../assets/Anti_crime.jpg'
import Education from '../../assets/Education.png'
import Human from '../../assets/Human.png'
import Health from '../../assets/Health.jpg'
import Social from '../../assets/Social-Welfare.jpg'

const Objective = () => {
  return (
    <div className='.Objective-container '>
      <div className="objective-heading">
       <h3>Our Objectives</h3>
      </div>
      <div className='objectives-grid'>
        <div className='objectives-box'>
          <div className='objectives-image'>
            <img src={Social} alt="" />
          </div>
          <h3>Social Welfare</h3>
        </div>
        <div className='objectives-box'>
          <div className='objectives-image'>
            <img src={Health} alt="" />
          </div>
          <h3>HEALTH & RESEARCH</h3>
        </div>
        <div className='objectives-box'>
          <div className='objectives-image'>
            <img src={Education} alt="" />
          </div>
          <h3>EDUCATION & TRAINING</h3>
        </div>
        <div className='objectives-box'>
          <div className='objectives-image'>
            <img src={Human} alt="" />
          </div>
          <h3>Human Rights</h3>
        </div>
        <div className='objectives-box'>
          <div className='objectives-image'>
            <img src={antiCrime} alt="" />
          </div>
          <h3>Anti Crime</h3>
        </div>

      </div>
    </div>
  )
}

export default Objective