import React from 'react'
import './founder.css'
import imgFounder from '../../assets/founder.jpeg'
const Founder = () => {
  return (
    <div className="founder-container" id='Founder'>
      
      <div className='founder-subcontainer2'>
        <h3 className='Founder-heading'>About President</h3>
        <h2 className='founder-name'>Mr Dipesh Pashte - Director (Founder & President)</h2>
        <h3 className='founder-post'>Hon.PhD in Psychology</h3>

        <p>Overcoming extremely poor conditions, Shri Samarth Meditation & Study Skills Center was established in November 2013 by pursuing postgraduate education in Mathematics as well as MBA education and studying Yoga, Pranayama, Meditation and Ayurveda. Through this organization, he started social, cultural and educational as well as spiritual work all over India. The Fourth Pillar of Democracy - Journalism is a hobby and as it is a hobby of writing and reading. He has started a movement to eradicate superstitions as a meditation expert and counselor as he has contributed
          <br /><br />
          in the field of spirituality as well as in case of mental distress, without resorting to any technical magician. Due to his accurate knowledge of Yoga and Pranayama as well as Ayurveda, Shri Samarth Meditation & Study Skills Center is always providing free public awareness and service to the police administration and social service organizations through meditation. Due to his mastery of the conceptual structure and the art of speech, as well as his interest in pure thought and social work and his efforts to implement new ventures, he has held various positions in various organizations, establishments and organizations. The International Institute of Naturopathy (INO), New Delhi has also appointed a member of the organization as a member of the organization in view of all these factors as well as their passion and passion for the environment (tree planting and conservation).
          <br /><br />
          He has been appointed as the Director of the Human Rights Foundation in recognition of his passion for social work, his struggles and his ability to take Maharashtra to a higher level. He is being congratulated on all levels for getting justice and giving no thought to anyone in times of need. Everyone is hoping for immediate help. He is pursuing a PhD degree from International Global University with a focus on human rights, keeping in mind the desire to continue his education till the end while doing social work.</p>
      </div>

      <div className='founder-subcontainer'>
        <div className='img-box'>
          <div className="box box1"></div>
        
          <img className='img-founder' src={imgFounder} alt="" />
     
          <div className="box box2"></div>
        </div>
      </div>
    </div>
  )
}

export default Founder;