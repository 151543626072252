import React, { useEffect, useState } from 'react'
import './slider.css';

// import { Splide, SplideSlide } from "@splidejs/react-splide"
import '@splidejs/react-splide/css';
import axios from 'axios';
import { API, FILE_SERVER_URL } from '../../constant';

import { EffectCoverflow, Autoplay  } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import "swiper/css/effect-coverflow"

const Slider = () => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [allBanners, setAllBanners] = useState([]);
  useEffect(() => {
    axios.get(`${API}/admin/banners`)
      .then((res) => {
        console.log(res);
        const { banners } = res.data;
        setAllBanners(banners);
        setImageLoaded(true);
      })
      .catch(e => {
        setImageLoaded(true);
        console.log(e);
      })
  }, [])

  return (
    <div style={{
      width: "100%"
    }}>
      {imageLoaded && (
        <Swiper
          modules={[EffectCoverflow, Autoplay]}
          effect='coverflow'
          slidesPerView={3}
          spaceBetween={100}
          onSwiper={(sw) => {
          }}
          autoplay={{
            delay: 2000,
          }}
          loop={true}
        >
          {
            allBanners.map((banner, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <img className='imgeslide'
                    // style={{
                    //   height: "300px",
                    //   width: "500px",
                    //   borderRadius: '5px'
                    // }}
                    src={`${FILE_SERVER_URL}/${banner.image_path}`}
                    // src={`http://localhost:5000/banner/banner-1710598996671.jpg`}
                    alt=''
                  />
                </SwiperSlide>
              )
            })
          }
        </Swiper>
      )}
    </div>
  )
}

export default Slider;