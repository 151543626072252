import React from 'react'
import UnderProgress from '../UnderProcess/UnderProgress'

const About = () => {
  return (
    <div>
      <UnderProgress/>
    </div>
  )
}

export default About