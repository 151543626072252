import React, { Component } from 'react';
import './dashboard.css'
//  Icons For Dashbord
import { MdDashboard } from "react-icons/md";
import CanvasJS from '@canvasjs/charts';
import { HiOutlineUser, HiOutlineUsers } from 'react-icons/hi';
import { BiBookAlt } from 'react-icons/bi';
import { FiDollarSign } from 'react-icons/fi';
import { MdModeEdit } from 'react-icons/md';
import { AiOutlineDelete } from 'react-icons/ai';

const Dashboard = () => {
	return (
		<div className="admin-dashboard-page">
			<div className='dashboardRow1'>
				<div className='dashboardCounters c1'>
					<div className='counterIcon'>
						<HiOutlineUsers />
					</div>
					<div className="dashCounterData">
						<h4 className='counterTitle'>Total Members</h4>
						<p className='counterCount'>{0}</p>
						<div className='counterProgress'><p style={{ width: "45%" }}></p></div>
						<p className='counterTitle noWrap'>45% increase in 28 Days</p>
					</div>
				</div>
				<div className='dashboardCounters c2'>
					<div className='counterIcon'>
						<HiOutlineUser />
					</div>
					<div className="dashCounterData">
						<h4 className='counterTitle'>Verified Members</h4>
						<p className='counterCount'>{0}</p>
						<div className='counterProgress'><p style={{ width: "40%" }}></p></div>
						<p className='counterTitle noWrap'>40% increase in 28 Days</p>
					</div>
				</div>
				<div className='dashboardCounters c3'>
					<div className='counterIcon'>
						<BiBookAlt />
					</div>
					<div className="dashCounterData">
						<h4 className='counterTitle'>Total Events</h4>
						<p className='counterCount'>{ }</p>
						<div className='counterProgress'><p style={{ width: "85%" }}></p></div>
						<p className='counterTitle noWrap'>85% increase in 28 Days</p>
					</div>
				</div>
				<div className='dashboardCounters c4'>
					<div className='counterIcon'>
						<FiDollarSign />
					</div>
					<div className="dashCounterData">
						<h4 className='counterTitle'>This Months Events</h4>
						{/* <p className='counterCount'>12,500 $</p> */}
						<div className='counterProgress'><p style={{ width: "50%" }}></p></div>
						<p className='counterTitle noWrap'>50% increase in 28 Days</p>
					</div>
				</div>
			</div>

		</div>
	)
}

export default Dashboard