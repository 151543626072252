import React from 'react'
import './Team.css'
const Team = () => {
  return (
    <div className="team-container">
      <div className="teamData">
        <div className='add-teamMember'>
          <h4>Management Team</h4>
          <form action="">
            <div>
              <label htmlFor="">
                Team Member Name:
              </label><br />
              <input className='input-Style' type="text" />
            </div>

            <div>
              <label htmlFor="">
                Designation:
              </label><br />
              <input className='input-Style' type="text" />
            </div>
            <div>
              <label htmlFor="">
                Contact No:
              </label><br />
              <input className='input-Style' type="text" />
            </div>
            <div>
              <label htmlFor="">
                Facebook Link:
              </label><br />
              <input className='input-Style' type="text" />
            </div>

            <div>
              <label htmlFor="">
                Instagram:
              </label><br />
              <input className='input-Style' type="text" />
            </div>
            <div>
              <label htmlFor="">
                Twitter:
              </label><br />
              <input className='input-Style' type="text" />
            </div>
            <br />

            <button className='AMFButton'>Add Member</button>
          </form>
        </div>


        <div className='teamData-Table'>

        </div>
      </div>
    </div>
  )
}

export default Team