import React from 'react'
import axios from 'axios'
import './MemberPopup.css'
import { API, FILE_SERVER_URL } from '../../../constant'
import { Link } from 'react-router-dom'

const MemberPopup = ({ memberData, onClose, isOpen, updateMembershipVerifiedBy }) => {

    const verifyMembership = async (memberId) => {
        try {
            const res = await axios.post(`${API}/admin/membership/verify-membership`, { memberId }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            if (String(res.data.status).toLowerCase() === "success") {
                updateMembershipVerifiedBy(memberId, res.data?.data?.verified_by);
                onClose();
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className={`popup-container-backdrop ${isOpen ? "open" : ""}`}>

            <div className="popup-container">
                <div className="adminCard member-left">
                    <div className="member-img AdminMembershipTableImg">
                        <img src={`${FILE_SERVER_URL}/${memberData?.candidate_photo}`} />
                    </div>
                    <div className="descLine">
                        <b>Name:</b>
                        <p>{memberData?.full_name}</p>
                    </div>
                    <div className="descLine">
                        <b>Date Of Birth:</b>
                        <p>{memberData?.dob}</p>
                    </div>
                    <div className="descLine">
                        <b>Gender:</b>
                        <p>{memberData?.gender}</p>
                    </div>
                    <div className="descLine">
                        <b>Contact No:</b>
                        <p>{memberData?.contact_no}</p>
                    </div>
                    <div className="descLine">
                        <b>Address:</b>
                        <p>{memberData?.address}</p>
                    </div>

                    <div className="descLine">
                        <b>Email:</b>
                        <p>{memberData?.email}</p>
                    </div>
                    <div className="descLine">
                        <b>Reference:</b>
                        <p>{memberData?.reference}</p>
                    </div>
                    <div className="descLine">
                        <b>District:</b>
                        <p>{memberData?.district}</p>
                    </div>
                    <div className="descLine">
                        <b>Pin Code:</b>
                        <p>{memberData?.pincode}</p>
                    </div>
                    <div className="descLine">
                        <b>Aadhar Card No:</b>
                        <p>{memberData?.aadhar_no}</p>
                    </div>

                    <div className="descLine flexRow">
                        <b>Aadharcard Pdf:</b>
                        <Link target='_blank' to={`${FILE_SERVER_URL}/${memberData?.aadhar_pdf_url}`} className='AMFLink sm'>View</Link>
                    </div>
                    <div className="descLine flexRow">
                        <b>Police Verification:</b>
                        <Link target='_blank' to={`${FILE_SERVER_URL}/${memberData?.police_verification_url}`} className='AMFLink sm'>View</Link>
                    </div>
                    {
                        memberData?.id_card_no && memberData?.id_card_path &&
                        <div className="descLine flexRow">
                            <b>ID Card:</b>
                            <Link target='_blank' to={`${FILE_SERVER_URL}/${memberData?.id_card_path?.url}`} className='AMFLink sm'>View</Link>
                        </div>
                    }
                </div>
                <div className="memberPopupBtns">
                    <button className='AMFButton md' onClick={onClose}>Close</button>
                    {!memberData?.verified && (
                        <button className='AMFButton md' onClick={() => verifyMembership(memberData?._id)}>Verify</button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default MemberPopup