import React from 'react'
import './ManagementTeam.css'

// Imgaes 
import ItDep from '../../assets/kaushal.png'
import Team1 from '../../assets/team1.jpg'
import Team2 from '../../assets/team2.jpg'
import Team3 from '../../assets/team3.jpg'
import Team4 from '../../assets/team4.jpg'




const ManagementTeam = () => {
    return (
        <div className="ManagementTeam-container">
            <div className="ManagementTeam-hedding">
                <p>Management Team</p>
            </div>
            <div className="home-container">
                <div className="profile-card">
                    <div className="img">
                        <img src={Team1} />
                    </div>
                    <div className="caption">
                        <h3>Mr. Praful Bhatkar.</h3>
                        <p>Addtional Director.</p>
                        <div className="social-links">
                            <i className="fa-brands fa-facebook"></i>
                            <i className="fa-brands fa-twitter"></i>
                            <i className="fa-brands fa-instagram"></i>
                        </div>
                    </div>
                </div>
                <div className="profile-card">
                    <div className="img">
                        <img src={Team2} />
                    </div>
                    <div className="caption">
                        <h3>Mr. Divesh Pashte.</h3>
                        <p>Chief Executive Officer.</p>
                        <div className="social-links">
                            <i className="fa-brands fa-facebook"></i>
                            <i className="fa-brands fa-twitter"></i>
                            <i className="fa-brands fa-instagram"></i>
                        </div>
                    </div>
                </div>
                <div className="profile-card">
                    <div className="img">
                        <img src={Team3} />
                    </div>
                    <div className="caption">
                        <h3>Mr. Ganesh Umratkar.</h3>
                        <p>President, Maharashtra State.</p>
                        <div className="social-links">
                            <i className="fa-brands fa-facebook"></i>
                            <i className="fa-brands fa-twitter"></i>
                            <i className="fa-brands fa-instagram"></i>
                        </div>
                    </div>
                </div>
                <div className="profile-card">
                    <div className="img">
                        <img src={Team4} />
                    </div>
                    <div className="caption">
                        <h3>Miss. Renika Dighe</h3>
                        <p>Secretary,  Maharashtra State. </p>
                        <div className="social-links">
                            <i className="fa-brands fa-facebook"></i>
                            <i className="fa-brands fa-twitter"></i>
                            <i className="fa-brands fa-instagram"></i>
                        </div>
                    </div>
                </div>
                <div className="profile-card">
                    <div className="img">
                        <img src={ItDep} />
                    </div>
                    <div className="caption IT">
                        <h3>Mr. Kaushal Patil.</h3>
                        <p>IT Departent.</p>
                        <div className="social-links">
                            <i className="fa-brands fa-facebook"></i>
                            <i className="fa-brands fa-twitter"></i>
                            <i className="fa-brands fa-instagram"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManagementTeam