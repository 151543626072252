import React from 'react'
import './Act.css'
import UnderProgress from '../UnderProcess/UnderProgress'
// import pdf1 from '../../../../server/media/PDF/PHRA_Bilingual_2018.pdf'
const Act = () => {
  return (
    <div>
      <UnderProgress/>
    </div>
  )
}

export default Act