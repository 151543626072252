import React from 'react'
import './FAQ.css'
import HumanRightsIMG from '../../assets/bg-banner.jpg'
const FAQ = () => {




  return (
    <div className="faq-container">
      <div className="faq-wrapper">
        <div className="faq-img">
          <img src={HumanRightsIMG} alt="" />
        </div>

        <div className="faq-box">
          <ul className="accordion">
            <div className="faq-heading">

          <h2>
            FREQUENTLY ASKED QUESTIONS
          </h2>
          <p> 
            The National Human Rights Commission is an expression of India's concern for the protection and promotion of human rights. It came into being in October,1993.
          </p>
            </div>

            {/* List  */}
            <li>
              <input className='faq-button' type="radio" name="accordion" id="first" />
              <label htmlFor="first">How are human rights defined in the Protection of Human Rights Act, 1993?</label>
              <div className="faq-containt">
                <p>In terms of Section 2 of the Protection of Human Rights Act, 1993 (hereafter referred to as 'the Act'), "human rights" means the rights relating to life, liberty, equality and dignity of the individual guaranteed under the Constitution or embodied in the International Covenants and enforceable by courts in India. "International Covenants" means the International Covenant on Civil and Political Rights and the International Covenant on Economic, Social and Cultural Rights adopted by the General Assembly of the United Nations on the 16th December, 1966.</p>
              </div>
            </li>

            {/* List  */}
            <li>
              <input className='faq-button' type="radio" name="accordion" id="secand" />
              <label htmlFor="secand">What functions have been assigned to the Commission under the Act?</label>
              <div className="faq-containt">
                <p>The Commission shall, perform all or any of the following functions, namely -
                  <br />
                  a) Inquire, on its own initiative or on a petition presented to it by a victim or any person on his behalf, into complaint of- i ) violation of human rights or abetment or ii) negligence in the prevention of such violation, by a public servant.
                  <br />
                  b) intervene in any proceeding involving any allegation of violation of human rights pending before a court with the approval of such court.
                  <br />
                  c) visit, under intimation to the State Government, any jail or any other institution under the control of the State Government, where persons are detained or lodged for purposes of treatment, reformation or protection to study the living condition of the inmates and make recommendations thereon .
                  <br />
                  d) review the safeguards by or under the Constitution or any law for the time being in force for the protection of human rights and recommend measures for their effective implementation.
                  <br />
                  e) review the factors, including acts of terrorism that inhibit the enjoyment of human rights and recommend appropriate remedial measures; f) study treaties and other international instruments on human rights and make recommendations for their effective implementation.
                  <br />
                  g) undertake and promote research in the field of human rights.
                  <br />
                  h) spread human rights literacy among various sections of society and promote awareness of the safeguards available for the protection of these rights through publications, the media, seminars and other available means.
                  <br />
                  i) encourage the efforts of non - Governmental organizations and institutions working in the field of human rights.
                  <br />
                  j) such other functions as it may consider necessary for the promotion of human rights.</p>
              </div>
            </li>

            {/* List  */}
            <li>
              <input className='faq-button' type="radio" name="accordion" id="three" />
              <label htmlFor="three">What powers have been vested with the Commission relating to inquiries?</label>
              <div className="faq-containt">
                <p>While inquiring into complaints under the Act, the Commission shall have all the powers of a civil court trying a suit under the Code of Civil Procedure, 1908, and in particular the following, namely:
                  <br />
                  a) Summoning and enforcing the attendance of witnesses and examining them on oath.
                  <br />
                  b) discovery and production of any document.
                  <br />
                  c) receiving evidence on affidavits.
                  <br />
                  d) requisitioning any public record or copy thereof from any court or office.
                  <br />
                  e) issuing commissions for the examination of witnesses or documents.
                  <br />
                  f) any other matter which may be prescribed</p>
              </div>
            </li>

            {/* List  */}
            <li>
              <input className='faq-button' type="radio" name="accordion" id="four" />
              <label htmlFor="four">Does the Commission have its own investigation team?</label>
              <div className="faq-containt">
                <p>Yes, the Commission has its own investigating staff headed by a Director General of Police for investigation into complaints of human rights violations. Under the Act, it is open to the Commission to utilise the services of any officer or investigation agency of the Central Government or any State Government. The Commission has associated, in a number of cases, non - Governmental organizations in the investigation work.</p>
              </div>
            </li>


            {/* List  */}
            <li>
              <input className='faq-button' type="radio" name="accordion" id="five" />
              <label htmlFor="five">Is the Commission Autonomous?</label>
              <div className="faq-containt">
                <p>Yes, the autonomy of the Commission derives, inter-alia, from the method of appointing its Chairperson and Members, their fixity of tenure, and statutory guarantees thereto, the status they have been accorded and the manner in which the staff responsible to the Commission - including its investigative agency - will be appointed and conduct themselves. The financial autonomy of the Commission is spelt out in Section 32 of the Act.
                  <br />
                  The Chairperson and Members of the Commission are appointed by the President on the basis of recommendations of a Committee comprising the Prime Minister as the Chairperson, the Speaker of Lok Sabha, the Home Minister, the leaders of the opposition in the Lok Sabha and Rajya Sabha and the Deputy Chairman of the Rajya Sabha as Members.</p>
              </div>
            </li>


            {/* List  */}
            <li>
              <input className='faq-button' type="radio" name="accordion" id="six" />
              <label htmlFor="six">How does the Commission inquire into complaints?</label>
              <div className="faq-containt">
                <p>The Commission while inquiring into complaints of violations of human rights may call for information or report from the Central Government or any State Government or any other authority or organization subordinate thereto within such time as may be specified by it; provided that if the information or report is not received within the time stipulated by the Commission, it may proceed to inquire into the complaint on its own; on the other hand, if, on receipt of information or report, the Commission is satisfied either that no further inquiry is required or that the required action has been initiated or taken by the concerned Government or authority, it may not proceed with the complaint and inform the complainant accordingly.</p>
              </div>
            </li>



            {/* List  */}
            <li>
              <input className='faq-button' type="radio" name="accordion" id="seven" />
              <label htmlFor="seven">What steps are open to the Commission after inquiry?</label>
              <div className="faq-containt">
                <p>1) Where the inquiry discloses the commission of violation of human right or negligence in the prevention of violation of human rights by a public servant, it may recommend to the concerned Government or authority the initiation of proceedings for prosecution or such other action as the Commission may deem fit against the concerned person or persons.
                  <br />
                  2) Approach the Supreme Court or the High Court concerned for such directions, orders or writs as that Court may deem necessary.
                  <br />
                  3) Recommend to the concerned Government or authority for the grant of such immediate interim relief to the victim or the members of his family as the Commission may consider necessary.</p>
              </div>
            </li>


            {/* List  */}
            <li>
              <input className='faq-button' type="radio" name="accordion" id="eight" />
              <label htmlFor="eight">What procedure is prescribed under the Act with respect to armed forces?</label>
              <div className="faq-containt">
                <p>The Commission may on its own motion or on the basis of petitions made to it on allegations of human rights violations by armed forces, seek a report from the Central Government. On receipt of the report, it may either not proceed with the complaint or, as the case may be, make its recommendations to the Government. According to the Act, the Central Government shall inform the Commission of the action taken on the recommendations within three months or such further time as the Commission may allow. It is further stipulated that the Commission shall publish its report together with its recommendations made to the Central Government and the action taken by that Government on such recommendations. A copy of the report so published will also be given to the petitioner.</p>
              </div>
            </li>


            {/* List  */}
            <li>
              <input className='faq-button' type="radio" name="accordion" id="nine" />
              <label htmlFor="nine">Can the complaint be in any language?</label>
              <div className="faq-containt">
                <p>They may be in Hindi, English or in any language included in the Eighth Schedule of the Constitution. The complaints are expected to be self contained. No fee is charged on complaints. The Commission may ask for further information and affidavits to be filed in support of allegations whenever considered necessary. The Commission may in its discretion, accept telegraphic complaints and complaints conveyed through FAX or by e-mail. Complaints can also be made on the mobile telephone number of the Commission.</p>
              </div>
            </li>



            {/* List  */}
            <li>
              <input className='faq-button' type="radio" name="accordion" id="ten" />
              <label htmlFor="ten">What kind of complaints are not entertained by the Commission?</label>
              <div className="faq-containt">
                <p>Ordinarily, complaints of the following nature are not entertained by the Commission:
                  <br />
                  a) In regard to events which happened more than one year before the making of the complaints.
                  <br />
                  b) With regard to matters which are sub-judice.
                  <br />
                  c) Which are vague, anonymous or pseudonymous.
                  <br />
                  d) Which are of frivolous nature.
                  <br />
                  e) Which pertain to service matters.</p>
              </div>
            </li>



            {/* List  */}
            <li>
              <input className='faq-button' type="radio" name="accordion" id="eleven" />
              <label htmlFor="eleven">What is the responsibility of the authority/State/Central Governments to which reports/recommendations have been send by the Commission? </label>
              <div className="faq-containt">
                <p>The authority/State Government/Central Government has to indicate its comments/action taken on the report/recommendations of the Commission within a period of one month in respect of general complaints and within three months in respect of complaints relating to armed forces.</p>
              </div>
            </li>



            {/* List  */}
            <li>
              <input className='faq-button' type="radio" name="accordion" id="towhel" />
              <label htmlFor="towhel">What are the kinds of issues on which complaints have been received?</label>
              <div className="faq-containt">
                <p>Since its inception, the Commission has handled a variety of types of complaints. In the latest period, the major types of complaints have been:
                  <br />
                  In respect of police administration
                  <br />
                  Failure in taking action
                  <br />
                  Unlawful detention
                  <br />
                  False implication
                  <br />
                  Custodial violence
                  <br />
                  llIegal arrest
                  <br />
                  Other police excesses
                  <br />
                  Custodial deaths
                  <br />
                  Encounter deaths
                  <br />
                  Harassment of prisoners, jail conditions
                  <br />
                  Atrocities on SCs and STs
                  <br />
                  Bonded labour, child labour
                  <br />
                  Child marriage
                  <br />
                  Communal violence
                  <br />
                  Dowry death or its attempt, dowry demand
                  <br />
                  Abduction, rape and murder
                  <br />
                  Sexual harassment and indignity to women, exploitation of women
                  <br />
                  Numerous other complaints which cannot be categorized, have also been taken up.</p>
              </div>
            </li>

            {/* List  */}
            <li>
              <input className='faq-button' type="radio" name="accordion" id="fifteen" />
              <label htmlFor="fifteen">What are its major initiatives?</label>
              <div className="faq-containt">
                <p>Civil Liberties
                  <br />
                  Review of statutes, including Terrorist & Disruptive Activities Act, and (draft) Prevention of Terrorism Bill, 2000
                  <br />
                  Protection of human rights in areas of insurgency and terrorism
                  <br />
                  Guidelines to check misuse of the power of arrest by the police
                  <br />
                  Setting up of Human Rights Cells in the State/City Police Headquarters
                  <br />
                  Steps to check custodial deaths, rape and torture
                  <br />
                  Accession to the Convention against Torture, Additional Protocols to the Geneva Conventions.
                  <br />
                  Discussion on adoption of a Refugee Law for the country
                  <br />
                  Systemic reforms of police, prisons and other centers of detention
                  <br />
                  Visit to Jails, mental hospitals and similar other institutions
                  <br />
                  Review of laws, implementation of treaties, and the international instruments on human rights
                  <br />
                  Economic, Social & Cultural Rights
                  <br />
                  Elimination of bonded labour and child labour Issues concerning Right to Food
                  <br />
                  Prevention of maternal anaemia and congenital mental disabilities In the child
                  <br />
                  Human Rights of persons affected by HIV/AIDS
                  <br />
                  Public Health as a human rights issue
                  <br />
                  Rights of the vulnerable groups
                  <br />
                  Rights of women and children, minorities, scheduled castes and scheduled tribes
                  <br />
                  People displaced by mega projects
                  <br />
                  People affected by major disasters such as the super-cyclone in Orissa and the earthquake in Gujarat.
                  <br />
                  Monitoring the functioning of the Mental hospitals at Ranchi, Agra and Gwalior, and the Agra Protection Home, under a Supreme Court remit.
                  <br />
                  Action Research on Trafficking
                  <br />
                  Promotion and protection of the rights of the disabled.
                  <br />
                  Rights of Denotified and nomadic tribes
                  <br />
                  Welfare of the destitute widows of Vrindavan
                  <br />
                  Elimination of manual scavenging
                  <br />
                  Promotion of human rights literacy and awareness in the educational system and more widely in society.
                  <br />
                  Human rights training for the armed forces and police, public authorities, civil society, and students
                  <br />
                  Research through well-known academic institutions and NGOs on various issues relating to human rights
                  <br />
                  Publication of Annual Report, monthly Newsletter, Annual Journal, and research studies.
                  <br />
                  Consultation with NGOs and experts/specialists on Human Rights Issues</p>
              </div>
            </li>


            {/* List  */}
            <li>
              <input className='faq-button' type="radio" name="accordion" id="sixteen" />
              <label htmlFor="sixteen">Where is the Commission located and what are its contact numbers ?</label>
              <div className="faq-containt">
                <p>National Human Rights Commission
                  <br />
                  Manav Adhikar Bhawan Block-C,
                  <br />
                  GPO Complex, INA, New Delhi - 110023
                  <br />
                  Facilitation Centre (Madad): (011) 24651330, 24663333
                  <br />
                  Fax No. (011) 24651332
                  <br />
                  Web site: www.nhrc.nic.in</p>
              </div>
            </li>
          </ul>


        </div>

      </div>
    </div>
  )
}

export default FAQ