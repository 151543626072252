import React, { useRef } from 'react'
import './navbar.css';
import Logo from '../../assets/logo.jpeg'
import { HiOutlineBars3CenterLeft } from "react-icons/hi2";
import { ImCross } from "react-icons/im";
import { UseAppCtx } from '../../context/AppContext';
import {Link, useNavigate} from 'react-router-dom'



const Navbar = () => {
  const navigate = useNavigate();
  const {navbarState, toggle} = UseAppCtx()

  return (
    <header>
      <nav className='nav'>
        <div className='nav-brand'>
          <img className='brand-logo' src={Logo} alt="" />
          <a className='brand-name' href="">Aple Manavadhikar Foundation</a>  
        </div>
        <ul className='nav-links desktop'>
          <li><Link to={"/"} >Home</Link></li>
          <li ><Link to={"/Founder"} >About Founder</Link></li>
          <li ><Link to={"/HumanRights"} >Human Rights</Link></li>
          <li><Link to={"/FAQ"} >FAQs</Link></li>
          <li> <Link to={"/Act"} >Act & Rules</Link></li>
          <li>  <Link to={"/About"} >About us</Link></li>
          <li> <Link to={"/Contact"} >Contact Us</Link></li>
        </ul>
        <button className='btn-member' onClick={()=>navigate('/membership')} href="#membership">MemberShip</button>
        <div className='toggle-button' onClick={toggle}>
          <p>
            <HiOutlineBars3CenterLeft />
          </p>
        </div>
      </nav>
        <ul className={`nav-links mobile ${navbarState ? "active": ""}`}>
        <li><Link to={"/"}  onClick={toggle} >Home</Link></li>
          <li ><Link to={"/Founder"}  onClick={toggle}>About Founder</Link></li>
          <li ><Link to={"/HumanRights"}  onClick={toggle}>Human Rights</Link></li>
          <li><Link to={"/FAQ"}  onClick={toggle}>FAQs</Link></li>
          <li> <Link to={"/Act"}  onClick={toggle}>Act & Rules</Link></li>
          <li>  <Link to={"/About"}  onClick={toggle}>About us</Link></li>
          <li> <Link to={"/Contact"}  onClick={toggle}>Contact Us</Link></li>
          <button className='btn-member' onClick={toggle}>MemberShip</button>
        </ul>
    </header>
  )
}


export default Navbar