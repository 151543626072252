import React from 'react'
import './HumanRights.css'
// import '../../../../server/media/PDF/act-draft-1994.pdf'
import HumanRightsIMG from '../../assets/bg-banner.jpg'
const HumanRights = () => {
  return (
    <div className="humanrights-container">
      <div className="humanrights-img">
        <img src={HumanRightsIMG} alt="" />
      </div>
        <div className="humanrights-infobox">
          <div className="humanrights-info">
            <div className='humanrights-heading'>
            <h3 >Human Rights In India.</h3>
            </div>
            <p>Human rights in India is an issue complicated by the country's large size & population, widespread poverty, lack of proper education & its diverse culture, even though being the world's largest sovereign, secular, democratic republic. The Constitution of India provides for Fundamental rights, which include freedom of religion. Clauses also provide for freedom of speech, as well as separation of executive and judiciary and freedom of movement within the country and abroad. The country also has an independent judiciary and well as bodies to look into issues of human rights.
              <br />The 2016 report of Human Rights Watch accepts the above-mentioned faculties but goes to state that India has "serious human rights concerns. Civil society groups face harassment and government critics face intimidation and lawsuits. Free speech has come under attack both from the state and by interest groups. Muslim and Christian minorities accuse authorities of not doing enough to protect their rights. But in the recent years, more emphasis is given to minority rights & freedom of speech.The government is yet to repeal laws that grant public officials and security forces immunity from prosecution for abuses."</p>
          </div>
          <div className="humanrights-info">
          <div className='humanrights-heading'>
            <h3>PROTECTION OF HUMAN RIGHTS ACT, 1994.</h3>
            </div>
            <p>An Act to provide for the constitution of a National Human Rights Commission, State Human Rights Commission in States and Human Rights Courts for better protection of human rights and for matters connected therewith or incidental thereto. Be it enacted by Parliament in the forty-fourth year of the Republic of India as follows :
              <br />* As amended by the Protection of Human Rights (Amendment) Act, 2006– No. 43 of 2006.
              <br /> <br /> <button className='AMFButton md'>Read full article</button>

            </p>
          </div>
          <div className="humanrights-info">
          <div className='humanrights-heading'>
            <h3>UNIVERSAL DECLAIRATION OF HUMAN RIGHTS</h3>
        </div>
            <p>
              The Universal Declaration of Human Rights (UDHR) is a milestone document in the history of human rights. Drafted by representatives with different legal and cultural backgrounds from all regions of the world, it set out, for the first time, fundamental human rights to be universally protected.

              <br />The Declaration was adopted by the UN General Assembly in Paris on 10 December 1948 during its 183rd plenary meeting:

              <br />Text : Resolution 217 A (III) <br />
              Voting information : Voting record  & Meeting records
              <br />  For more information about the history of the UDHR, see our UDHR research guides and resources.
            </p>
          </div>
        </div>
      </div>
  )
}

export default HumanRights