import React from 'react'
import './Contact.css'
import { BiLocationPlus } from "react-icons/bi";
import { MdPermPhoneMsg  } from "react-icons/md";
import { HiOutlineMailOpen } from "react-icons/hi";
import { IoMdClock } from "react-icons/io";



const Contact = () => {
  return (
    <div className="mapPage">
            <h2>Contact Us</h2>
            <div className="MapInnerContainer">
                <div className="MapArea">
                    <iframe
                        title="GMap"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3760.20931166729!2d73.09655887467616!3d19.532625337479985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7754ffa3d38c1%3A0x275926a9de6b0f33!2sAple%20Manavadhikar%20Foundation!5e0!3m2!1sen!2sin!4v1719461154176!5m2!1sen!2sin"
                        // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1880.1279874132676!2d73.09804367262315!3d19.53062099423398!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x44780a5ca1b0d103%3A0x1dff869e66e70375!2sPatil%20Broadband%20Service!5e0!3m2!1sen!2sin!4v1705381688550!5m2!1sen!2sin" 
                        width="600" height="450"
                        style={{
                            border: 0,
                        }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className="MapDetailsArea">
                    <div className="MapDetailItem">
                        <div className="MapDetailItemIcon">
                            <BiLocationPlus color={"orange"} size={35} />
                        </div>
                        <div className="flexCol gapSM">
                            <h3>Our Office Address</h3>
                            <p>Office : 26, Soni-Siddhi Complex, 1st Floor, Beside Paralysis Centre, Wada-Bhiwandi Road, Kudus, Tel. Wada, Dist. Palghar-421312, Maharashtra</p>
                        </div>
                    </div>
                    <div className="MapDetailItem">
                        <div className="MapDetailItemIcon">
                            <HiOutlineMailOpen color={"orange"} size={35} />
                        </div>
                        <div className="flexCol gapSM">
                            <h3>General Enquiries</h3>
                            <p>officialaplemanavadhikar@gmail.com</p>
                        </div>
                    </div>
                    <div className="MapDetailItem">
                        <div className="MapDetailItemIcon">
                            <MdPermPhoneMsg color={"orange"} size={35} />
                        </div>
                        <div className="flexCol gapSM">
                            <h3>Call Us</h3>
                            <p>+91 8483901049</p>
                        </div>
                    </div>
                    <div className="MapDetailItem">
                        <div className="MapDetailItemIcon">
                            <IoMdClock color={"orange"} size={35} />

                        </div>
                        <div className="flexCol gapSM">
                            <h3>Our Timing</h3>
                            <p>10 AM - 10 PM</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="ContactFormWrapper">

            <form action="" className="ContactForm">
                <h2>Get In Touch</h2>
                <div className="CInputGroup">
                    <label htmlFor="name">Name</label>
                    <input type="text" id="name" name='name' />
                </div>
                <div className="CInputGroup">
                    <label htmlFor="address">Address</label>
                    <input type="text" id="address" name='address' />
                </div>
                
                <div className="CInputGroup">
                    <label htmlFor="phoneno">Phone Number</label>
                    <input type="text" id="phoneno" name='phoneno' />
                </div>
                <div className="ContactSubmitBtn">
                    <button>Send</button>
                </div>
            </form>
            </div>
        </div>
  )
}

export default Contact