import React, { useEffect, useState } from 'react'
import { MdCardMembership } from "react-icons/md";
import { MdOutlineLibraryAdd } from "react-icons/md";
import axios from 'axios';
import { API } from '../../../constant';
import { toast } from 'react-toastify';
import './LatestActivity.css'

const LatestActivity = () => {
	const [eventFormVisible, setEventFormVisible] = useState(false);
	const [eventData, seteventData] = useState([]);
	const [eventItemData, seteventItemData] = useState({
		eventname: "",
		datetime:"",
		description: "",
		image: "",
	});

	function getBase64(e, cb) {
		const file = e.target.files[0];
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			seteventItemData(p => ({
				...p,
				image: reader.result
			}));
			console.log(reader.result);
		};
		reader.onerror = function (error) {
			console.log('Error: ', error);
		};
	}

	const onChangeInput = (e) => {
		const { name, value } = e.target;
		seteventItemData(p => ({
			...p,
			[name]: value
		}))
	}

	useEffect(() => {
		axios.get(`${API}/admin/events`)
			.then((res) => {
				console.log(res);
				const { banners } = res.data;
				seteventData(banners);
			})
			.catch(e => {
				console.log(e);
			})
	}, [])

	const handleSubmit = (e) => {
		e.preventDefault();
		axios.post(`${API}/admin/events/add`, eventItemData)
			.then(res => {
				console.log(res);
				seteventData(p => [
					...p,
					res.data.data
				])
				setEventFormVisible(false)
				seteventItemData({})
			})
			.catch((e) => {
				console.log(e);
			})
	}

	const deleteBanner = async (id) => {
		try {
			const res = await axios.delete(`${API}/admin/events/delete/${id}`);
			const { status } = res.data;
			if (status === "success") {
				toast.success("Banner deleted successfuly")
				seteventData(p => {
					const filterSlider = p.filter((i) => String(i._id) !== String(id))
					return filterSlider;
				})
			}
		} catch (error) {
			console.log(error);
		}
	}


  return (
    <div className="admin-slider-page">
			<div className="header">
				<h4>Letest Activity</h4>
				<p><MdCardMembership />Letest Activity</p>
			</div>
			<div className="slider-data">
				<div className="responsiveTable ">
					<div className='Slider-nav'>
						<div className="buttonslide" onClick={() => setEventFormVisible(true)}>
							<MdOutlineLibraryAdd /> <h5>Add Event Details</h5>
						</div>
					</div>
					<table className="styled-table striped AdminslidershipTable">

						<thead>
							<tr>
								<th>Sr No</th>
								<th>Date</th>
								<th>Event Name</th>
								<th>Image</th>
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							{eventData.map((eventItem, idx) => (
								<tr>
									<td>{idx + 1}</td>
									<td>{eventItem.eventname}</td>
									<td>{eventItem.datetime}</td>
									<td>{eventItem.description}</td>
									<td>
										<a className='image_container_view'
											style={{
												"--background": `url('${API}/upload/${eventItem?.image_path}')`
											}}
											data-image={`url('${API}/upload/${eventItem?.image_path}')`} href={`${API}/upload/${eventItem?.image_path}`} target='_blank'>{eventItem.image_name}</a>
									</td>
									<td>
										<button type='button' onClick={() => deleteBanner(eventItem?._id)}>Delete</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>


      {
				eventFormVisible &&
				<div className="slideadd">
					<form action="" id='slidepopup' onSubmit={handleSubmit} className='slide-form'>
						<h3>Add Slide</h3>
						<div className='AMFSliderInputGroup'>
							<label htmlFor="eventname">Event Name:</label>
							<input type="text" name='eventname' id='eventname' onChange={onChangeInput} value={eventItemData.eventname} />
						</div>
						<div className='AMFSliderInputGroup'>
							<label htmlFor="eventdatetime">Date And Time:</label>
							<input type="datetime-local" id="birthdaytime" name="birthdaytime"  onChange={onChangeInput} value={eventItemData.datetime} />
							</div>
						<div className='AMFSliderInputGroup'>
							<label htmlFor="description">Description:</label>
							<textarea type="textarea" rows="2" cols="50" name='description' id='description' onChange={onChangeInput} value={eventItemData.description} />
						</div>

						<div className='AMFSliderInputGroup'>
							<label htmlFor="imagefile">Image-Slide:</label>
							<input type="file" name='imagefile' id='imagefile'
								value={eventItemData.imagefile}
								onChange={getBase64}

							/>
						</div>
						<br />
						<div className="slide-buttons">
							<button className='AMFButton md' onClick={() => setEventFormVisible(p => !p)}>Close</button>
							<button className='AMFButton md'>Add EventInfo</button>
						</div>
					</form>
				</div>
			}
      
      </div>
  )
}

export default LatestActivity