import React from 'react'
import './Footer.css'
import Logo from '../../assets/logo.jpeg';
import { FaPhoneFlip } from "react-icons/fa6";
import { IoMailUnread } from "react-icons/io5";
import { FaMapMarkerAlt } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { RiTwitterXLine } from "react-icons/ri";
import { FaFacebookF } from "react-icons/fa";
import { FiYoutube } from "react-icons/fi";
import {Link, useNavigate} from 'react-router-dom'
const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer className="Footer">
      <div className="topFooter">
        <div className="TopFooterItem">
          <FaMapMarkerAlt size={35} />

          <div>
            <strong>Find Us</strong>
            <p>Kudus, MH</p>
          </div>

        </div>
        <div className="TopFooterItem">
          <FaPhoneFlip size={35} />

          <div>
            <strong>Call Us</strong>
            <p>+91 8483901049</p>
          </div>
        </div>
        <div className="TopFooterItem">
          <IoMailUnread size={35} />
          <div>
            <strong>Mail Us</strong>
            <p>officialaplemanavadhikar@gmail.com</p>
          </div>
        </div>
        <div></div>
      </div>
      <div className="mainFooter">
        <div className="FooterGrid">
          <div className="footerItem FooterBrandInfo">
            <img className='footer-logo' src={Logo} alt="" />
            <h4 className='footer-brandname'>Aple Manavadhikar Foundation</h4>

            <p>Office : 26, Soni-Siddhi Complex, 1st Floor, Beside Paralysis Centre, Wada-Bhiwandi Road, Kudus, Tel. Wada, Dist. Palghar-421312, Maharashtra.</p>
          </div>
          <div className="footerItem FooterLinks">
            <h3>Useful Links</h3>
            <ul className='footer-link-list'>
              <li><a href=""> About</a></li>
              <li><a href=""> Gallary</a></li>
              <li><a href=""> Our Team</a></li>
              <li><a href=""> FAQs</a></li>
              <li><a href=""> Act & Rules</a></li>
            </ul>
          </div>
          <div className="footerItem FooterJoinAndDonation">
            <h3>MemberShip</h3>
            <button className='AMFButton FooterFixedBtn btn1' onClick={()=>navigate('/membership')}>
              Join Us
            </button>

            <button className='AMFButton FooterFixedBtn'onClick={()=>navigate('/membership')}>
              Download ID Card
            </button>
          </div>
          <div className="footerItem">
            <div className="FooterSocialMediaLinks">
              <h3>Social Links</h3>
              <div className="FooterSocialMediaLinksItems">
                <div className="FooterSocialMediaLinksItem">
                  <BsInstagram />
                </div>
                <div className="FooterSocialMediaLinksItem">
                  <RiTwitterXLine />
                </div>
                <div className="FooterSocialMediaLinksItem">
                  <FaFacebookF />
                </div>
                <div className="FooterSocialMediaLinksItem">
                  <FiYoutube />
                </div>
              </div>
            </div>
            {/* <a href='https://razorpay.com/payment-button/pl_HAzswnhQYzXkey/view/?utm_source=payment_button&utm_medium=button&utm_campaign=payment_button' className="FooterPaymentBtn">
              RazorPay
            </a> */}
            <a
              href="https://razorpay.com/payment-button/pl_HAzswnhQYzXkey/view/?utm_source=payment_button&utm_medium=button&utm_campaign=payment_button"
              type="submit"
              className="PaymentButton PaymentButton--dark svelte-ekc7fv"
              style={{ background: "rgb(7, 25, 249)" }}
            >
              <svg
                width={18}
                height={20}
                viewBox="0 0 18 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="svelte-ekc7fv"
              >
                <path
                  d="M7.077 6.476l-.988 3.569 5.65-3.589-3.695 13.54 3.752.004 5.457-20L7.077 6.476z"
                  fill="#fff"
                  className="svelte-ekc7fv"
                ></path>
                <path
                  d="M1.455 14.308L0 20h7.202L10.149 8.42l-8.694 5.887z"
                  fill="#fff"
                  className="svelte-ekc7fv"
                />
              </svg>{" "}
              <div className="PaymentButton-contents svelte-ekc7fv">
                <span className="PaymentButton-text svelte-ekc7fv">Donate Now</span>{" "}
                <div className="PaymentButton-securedBy svelte-ekc7fv">
                  Secured by Razorpay
                </div>
              </div>
            </a>

          </div>
        </div>
      </div>
      <div className="bottomFooter">
        <p>Copyright ©2024 Designed and maintained by <a href="https://kaushalportfolio.vercel.app/"> Kaushal Patil.</a></p>
        {/* <a href="https://kaushalportfolio.vercel.app/"> Kaushal Patil</a> */}
      </div>
    </footer>
  )
}

export default Footer