import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import HomeBase from './base/HomeBase'
import Home from './pages/Home';
import AdminBase from './admin/components/AdminBase/AdminBase';
import Membership from './components/MemberShip/Membership';
import Member from './admin/views/Member/Member';
import Gallery from './admin/views/Gallery/Gallery';
import LatestActivity from './admin/views/LatestActivity/LatestActivity';
import Slider from './admin/views/Slider/Slider';
import Team from './admin/views/Team/Team';
import Youtube from './admin/views/Youtube/Youtube';
import Dashboard from './admin/views/Dashboard/Dashboard';
import Protected from './utils/Protected';
import AdminLogin from './admin/components/login/Login';
import Founder from './components/Founder/Founder';
import HumanRights from './components/HumanRights/HumanRights';
import FAQ from './components/FAQ/FAQ';
import Act from './components/Act/Act';
import About from './components/About/About';
import Contact from './components/Contact/Contact';






const router = createBrowserRouter([
    {
        path: "/admin/login",
        element: <AdminLogin />
    },
    {
        path:'/admin',
        element:<Protected/>,
        children: [
            {
                path: '/admin',
                element: <AdminBase />,
                children: [
                    {
                        path: '/admin/dashboard',
                        element: <Dashboard />,
                    },
                    {
                        path: '/admin/membership',
                        element: <Member />,
                    },
                    {
                        path: '/admin/letestactivity',
                        element: <LatestActivity/>,
                    },
                    {
                        path: '/admin/Slider',
                        element: <Slider />,
                    },
                    {
                        path: '/admin/gallery',
                        element: <Gallery />,
                    },
                    {
                        path: '/admin/team',
                        element: <Team />,
                    },
                    {
                        path: '/admin/youtube',
                        element: <Youtube />,
                    },
                ]
            }
        ]
    },
    {
        path: '/',
        element: <HomeBase />,
        children: [
            {
                path: '/',
                element: <Home />,
            },
            {
                path: '/Founder',
                element: <Founder />,
            },
            {
                path: '/HumanRights',
                element: <HumanRights />,
            },
            {
                path: '/FAQ',
                element: <FAQ />,
            },
            {
                path: '/Act',
                element: <Act />,
            },
            {
                path: '/About',
                element: <About />,
            },
            {
                path: '/Contact',
                element: <Contact />,
            },
            {
                path: '/membership',
                element: <Membership />,
            },
        ],
    },

])
function App() {
    return (
        <RouterProvider router={router} />
    );
}

export default App;
