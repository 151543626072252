import React from 'react'
import './Header.css'
import BrandLogo from '../../../assets/logo.jpeg'
const Header = () => {
  return (
    <div className="dashbord-header">
    <div className="brand">
      <img src={BrandLogo} alt="" />
      <h3>Aple Manavadhikar Foundation</h3>
    </div>
    <div>
      
    </div>
  </div>
  
  )
}

export default Header