import React from 'react'
import './Introduction.css'
const Introduction = () => {
    return (
        <div className="intro-container">
            <div></div>
            <div className='intro-wrapper'>

                <h2 className='Introduction-heading'>Brief introduction of Human rights.</h2>
                <h4>(Article by Dr. Vitthal Sallawar (MBBS) President, Nanded District)</h4>
                <div className='block-box'>
                    <div className='blockone'>
                        <p>
                            Human rights are rights inherent to all human beings, regardless of race, sex, nationality, ethnicity, language, religion, or any other status.                </p>

                        <p>
                            Human rights in Constitution of India are none other than Fundamental rights.                </p>
                        <p>
                            Part III of the Constitution is rightly described as the Magna Carta of India.                </p>

                        <p>
                            REFERENCE((""Magna Carta’ is the Charter of Rights issued by King John of England in 1215 under pressure from the barons. This is the first written document relating to the Fundamental Rights of citizens.))

                        </p>
                    </div>

                    <div className="blocktwo">
                        <h5>It contains a very long and comprehensive list of ‘justiciable’ Fundamental Rights. </h5>
                        <h5> Originally, the Constitution provided for seven Fundamental Rights viz,</h5>

                        <ol>
                            <li> Right to equality (Articles 14–18)</li>
                            <li> Right to freedom (Articles 19–22)</li>
                            <li> Right against exploitation (Articles 23–24)</li>
                            <li> Right to freedom of religion (Articles 25–28)</li>
                            <li> Cultural and educational rights (Articles 29–30)</li>
                            <li> Right to property (Article 31)</li>
                            <li> Right to constitutional remedies (Article 32)</li>
                        </ol>
                        However, the right to property was deleted from the list of Fundamental Rights by the 44th Amendment Act, 1978."
                    </div>

                    <div className='blockthree'>
                        <h3>REFERENCE</h3>
                        <h5>((Enacted by the Janata Government mainly to nullify some of the other distortions introduced by the 42nd AmendmentAct, 1976))</h5>
                    </div>
                    <div className='blockfour'>
                        <h3>FEATURES OF FUNDAMENTAL RIGHTS</h3>
                        <ul>
                            <li>They are not absolute but qualified</li>
                            <li>Some of them are negative in character</li>
                            <li>They are defended and guaranteed by the Supreme Court</li>
                            <li>They are not sacrosanct or permanent</li>
                            <li>Their application to the members of armed forces, para-military forces, police forces, intelligence agencies and analogous services can be restricted or abrogated by the Parliament</li>
                        </ul>
                    </div>
                    <h2>- Dr. Vitthal Sallawar (MBBS) President, Nanded District</h2>
                </div>
            </div>
            <div></div>
        </div>
    )
}

export default Introduction