import React from 'react'
import verfiedGIF from '../../assets/gifs/verified.gif';
import errorGIF from '../../assets/gifs/error.gif';

const CommonAlertBox = ({ type, message, onClose }) => {
    return (
        <div className='common-alert-box-container'>
            <div className='common-alert-box'>
                <div className='common-alert-box-img'>
                    {
                        type === "verified" &&
                        <img src={verfiedGIF} alt="" />
                    }
                    {
                        type === "error" &&
                        <img src={errorGIF} alt="" />
                    }
                </div>
                <div className='common-alert-box-msg'>
                    <p className='common-alert-box-msg-text'>{message}</p>
                    <button className='AMFButton sm' onClick={onClose}>close</button>
                </div>
            </div>
        </div>
    )
}

export default CommonAlertBox