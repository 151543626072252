import React from 'react'
import '../../admin.css';
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import { Outlet } from 'react-router'

const AdminBase = () => {
    return (
        <div className='admin-container'>
            <Header />
            <div className='Admin-Wrapper'>
                <Sidebar />
                <div className='AdminContentArea'>
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default AdminBase