import React from 'react'
import './Gallery.css';
import img1 from '../../assets/g1.jpeg'
import img2 from '../../assets/g2.jpeg'
import img3 from '../../assets/g3.jpeg'
import img4 from '../../assets/g4.jpeg'
import img5 from '../../assets/g5.jpeg'
import img6 from '../../assets/g6.jpeg'
import img7 from '../../assets/g7.jpeg'
// import img8 from '../../assets/g8.jpeg'
const Gallery = () => {
    return (
        <div className='HomePageGallery'>
            <h2>Gallery</h2>
            <div className="HomePageGalleryContainer">
                <div className="HomePageGalleryItem">
                    <img className="HomeSliderImg" src={img4} alt='' />
                </div>
                <div className="HomePageGalleryItem">
                <img className="HomeSliderImg" src={img2} alt='' />
                </div>
                <div className="HomePageGalleryItem">
                  <img className="HomeSliderImg" src={img1} alt='' />
                </div>
                <div className="HomePageGalleryItem">
                  <img className="HomeSliderImg" src={img3} alt='' />
                </div>
                <div className="HomePageGalleryItem">
                   <img className="HomeSliderImg" src={img6} alt='' />
                </div>
                <div className="HomePageGalleryItem">
                   <img className="HomeSliderImg" src={img7} alt='' />
                </div>
            </div>
        </div>
    )
}

export default Gallery