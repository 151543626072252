import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { API } from '../constant';
import { Outlet } from 'react-router-dom';

const Protected = ({ reverse, validRoles }) => {

    const [showComponent, setShowComponent] = useState(false);
    console.log("protected redendered");

    useEffect(() => {
        const checkLogin = () => {
            axios.post(`${API}/admin/user/check-login`, {}, {
                headers: {
                    token: localStorage.getItem("token")
                }
            }).then((res) => {
                if (reverse === true) {
                    if (res.data.logged_in) {
                        window.location.href = "/"
                    } else {
                        setShowComponent(true)
                    }
                } else {
                    if (!res.data.logged_in) {
                        const currUrl = window.location?.pathname;
                        window.location.href = `/admin/login?next=${currUrl}`;
                    } else {
                        if (!validRoles || validRoles === "" || validRoles?.length === 0) {
                            setShowComponent(true);
                        } else {
                            // check if user has valid role to access this page 
                            if (validRoles.includes(res.data?.role)) {
                                setShowComponent(true)
                            } else {
                                window.location.href = "/"
                                console.log("not authorised");
                            }
                        }
                    }
                }
            })
                .catch((e) => {
                    console.log(e);
                })
        }
        checkLogin();
    }, []);

    return (
        <div>
            {showComponent && <Outlet />}
        </div>
    )
}

export default Protected