import React from 'react'
import './Humanrightsinfo.css';
import '../../css/animation.css';
const Humanrightsinfo = () => {
  return (
    <div className="info-container">
      <div className="infobox" id='slideRight'>

        <h2>  Who we are</h2>
        <p>  We are a social, cultural, humanitarian, social service organization as well as an organization that works to expose injustice, raise voice against corruption and bring justice to all. If anyone facing any issue, you can reach out to us immediately. You will definitely get the right help from the Aple Manavadhikar Foundation.</p>
      </div>
      <div className="infobox" id='slideLeft'>
        <h2>What we do</h2>
        <p>We call our self Human rights defenders and we act peacefully for the promotion and protection of those rights. Human rights defenders are those men and women who act peacefully for the promotion and protection of those rights, and most of this activity happens within a nation as opposed to internationally. Our mission is fight agaist the human right violation. In efforts to eliminate violations of human rights, building awareness and protesting inhumane treatment has often led to calls for action and sometimes improved conditions.</p>
      </div>
    </div>
  )
}

export default Humanrightsinfo