import React, { useEffect, useState } from 'react'
import './member.css'
import { MdCardMembership } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import axios from 'axios';
import { API, FILE_SERVER_URL } from '../../../constant';
import MemberPopup from './MemberPopup';
import { AiOutlineDownload, AiOutlineEye } from 'react-icons/ai';
import CommonAlertBox from '../../../ui/alert/CommonAlertBox';

const Member = () => {

  const [memberData, setMemberData] = useState([]);

  const [MemberItemData, setMemberItemData] = useState({});
  const [showGenerateCardModal, setShowGenerateCardModal] = useState(false);
  const [alertData, setAlertData] = useState({
    show: false,
    type: "error",
    message: "Id Card Generration Failed"
  });
  const [generateCardForm, setGenerateCardForm] = useState({
    designation: "",
    constitution: "",
    memberId: ""
  });

  useEffect(() => {
    function getMemberData() {
      axios.get(`${API}/admin/membership`)
        .then((res) => {
          setMemberData(res.data?.data);
        })
    }
    getMemberData()
  }, [])

  const isItemSelected = () => {
    console.log(Object.keys(MemberItemData).length > 0);
    return Object.keys(MemberItemData).length > 0;
  }

  const updateMembershipVerifiedBy = (id, verified_by) => {
    try {
      console.log();

      setMemberData(p => {
        const updatedData = p.map((i) => {
          if (i._id === id) {
            i.verified_by = verified_by;
            i.verified = true;
          }
          return i;
        })
        console.log(updatedData);

        return updatedData;
      })
      setMemberItemData(p => {
        return {
          ...p,
          verified: true
        }
      })

    } catch (error) {
      console.log(error);

    }
  }

  const showGenerateCardPopup = (memberItemData) => {
    setShowGenerateCardModal(true);
    setGenerateCardForm(p => ({
      ...p,
      memberId: memberItemData?._id
    }))
  }

  const onChangeForm = (e) => {
    const { name, value } = e.target;
    setGenerateCardForm(p => {
      return {
        ...p,
        [name]: value
      }
    })
  }

  const generateNewIdCard = async () => {
    try {
      setShowGenerateCardModal(false);
      const { data } = await axios.post(`${API}/admin/membership/generate-idcard`, generateCardForm);
      console.log(data);
      if (String(data?.status).toLowerCase() !== "success") {
        setAlertData({
          show: true,
          type: "error",
          message: "Id Card Generration Failed"
        })
        return;
      }
      console.log(data);
      setAlertData({
        show: true,
        type: "verified",
        message: "Id Card Generated."
      })

    } catch (error) {
      setAlertData({
        show: true,
        type: "error",
        message: "Id Card Generration Failed"
      })
    }
  }

  return (
    <div className="admin-membership-page">
      <MemberPopup
        memberData={MemberItemData}
        onClose={() => setMemberItemData({})}
        isOpen={isItemSelected()}
        updateMembershipVerifiedBy={updateMembershipVerifiedBy}
      />
      {alertData.show && (
        <CommonAlertBox
          type={alertData?.type}
          message={alertData?.message}
          onClose={() => {
            setAlertData(p => ({ ...p, show: false }))
          }}
        />
      )}
      {
        showGenerateCardModal && (
          <div className='common-alert-box-container'>
            <div className='generate-card-popup'>
              <div>
                <label htmlFor="">Designation: </label>
                <input type="text" name='designation' value={generateCardForm.designation} onChange={onChangeForm} />
              </div>
              <div>
                <label htmlFor="">Constitution: </label>
                <input type="text" name='constitution' value={generateCardForm.constitution} onChange={onChangeForm} />
              </div>
              <div>
                <button type='button' className='AMFButton sm' onClick={generateNewIdCard}>Generate ID Card</button>
                <button type='button' className='AMFButton sm' onClick={() => setShowGenerateCardModal(false)}>Close</button>
              </div>
            </div>
          </div>
        )
      }

      <div className="header">
        <h4>MemberShip</h4>
        <p><MdCardMembership />MemberShip</p>
      </div>
      <div className="member-data">
        <div className="responsiveTable ">

          <table className="styled-table striped AdminMembershipTable">
            <thead>
              <tr>
                <th>Id</th>
                <th>Image</th>
                <th>Full Name</th>
                <th>Contact No</th>
                <th>District</th>
                <th>Verified</th>
                <th>Verified By</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                memberData.map((memberItem, idx) => (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>
                      <div className="AdminMembershipTableImg">
                        <img src={`${FILE_SERVER_URL}/${memberItem?.candidate_photo}`} />
                      </div>
                    </td>
                    <td>{memberItem?.full_name}</td>
                    <td>{memberItem?.contact_no}</td>
                    <td>{memberItem?.district}</td>
                    <td>{memberItem?.verified ? "Verified" : "Not Verified"}</td>
                    <td>{memberItem?.verified_by?.name}</td>
                    <td>
                      <div className='member-action-buttons'>
                         <AiOutlineEye size={20} className='ViewMemberDetailsBtn' onClick={() => setMemberItemData(memberItem)} />
                        <FaEdit size={20} className='FaEdit' onClick={() => setMemberItemData(memberItem)} />
                        {
                          memberItem?.id_card_generated ? (
                            <>
                            <a className='download-id-card' href={`${FILE_SERVER_URL}/${memberItem?.id_card_path?.url}`} target='_blank' download>
                              <AiOutlineDownload size={20} className='DownloadMemberIdCard' onClick={() => setMemberItemData(memberItem)} />
                            </a>
                            </>
                          ) : (
                            <button className='AMFButton sm' onClick={() => showGenerateCardPopup(memberItem)}>Generate ID Card</button>
                          )
                        }

                      </div>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>

    </div>
  )
}

export default Member