import React from 'react'
import './OurRights.css';
const OurRights = () => {
    return (
        <div className='ourRights-contaner'>
            <div className='ourrights-box'>
                        <h3>MAJOR HUMAN RIGHTS ISSUES AND VIOLATIONS</h3>
                <div className='ourrights-wrapper'>
                  <div className="ourRight-card">
                    <h5>COVID 19 & GLOBAL PANDEMIC</h5>
                    <ul>
                        <li>Vaccination</li>
                        <li>Avaibility of Oxygen</li>
                        <li>Avaibility of Medical facilities</li>
                        <li>Food, Shelter and masks</li>
                        <li>Social distancing and e-passes</li>
                        <li>Unemployement & Job losses</li>
                    </ul>
                  </div>
                  <div className="ourRight-card">
                  <h5>ADMINISTRATION OF JUSTICE</h5>
                    <ul>
                        <li>Abuse of power</li>
                        <li>Arbitrary detention</li>
                        <li> Cruel,Inhuman degrading treatment</li>
                        <li>Custodial care</li>
                        <li>Disappearance</li>
                        <li>Torture</li>
                    </ul>
                  </div>
                  <div className="ourRight-card">
                  <h5>PRISONERS/UNDER TRIALS/INMATES</h5>
                    <ul>
                        <li>Atrocities, Cruelty, Torture</li>
                        <li>Death & Rape in custody</li>
                        <li>Disappearances, Discrimination</li>
                        <li>Unnecessary handcuffing</li>
                        <li>Deprivation of legal aid</li>
                        <li>Unnecessary handcuffing</li>
                    </ul>
                  </div>
                  <div className="ourRight-card">
                  <h5>WOMEN HARASSMENT</h5>
                    <ul>
                        <li>Abduction, Eve-teasing</li>
                        <li>Dowry demand, Dowry death / attempt</li>
                        <li>Indignity, Exploitation</li>
                        <li>Mental harassment at workplace</li>
                        <li>Prostitution</li>
                        <li>Sexual harassment & rape</li>
                    </ul>
                  </div>
                  <div className="ourRight-card">
                  <h5>CHILDREN</h5>
                    <ul>
                        <li>Bonded labour, Early marriage</li>
                        <li>Exploitation, Prostitution</li>
                        <li>Kidnapping for begging</li>
                        <li>Immoral trafficking</li>
                        <li>Disappearance, Hunger & malnutrition</li>
                        <li>Denial of education</li>
                    </ul>
                  </div>
                  <div className="ourRight-card">
                  <h5>DISABLED PEOPLE</h5>
                    <ul>
                        <li>Denial of legitimate benefits</li>
                        <li>Medical facilities</li>
                        <li>Denial of proper care</li>
                        <li>Exclusion</li>
                        <li>Inadequate facilities</li>
                        <li>Unemployment</li>
                    </ul>
                  </div>
                  <div className="ourRight-card">
                  <h5>ADMINISTRATION OF JUSTICE</h5>
                    <ul>
                        <li>Abuse of power</li>
                        <li>Arbitrary detention</li>
                        <li> Cruel,Inhuman degrading treatment</li>
                        <li>Custodial care</li>
                        <li>Disappearance</li>
                        <li>Torture</li>
                    </ul>
                  </div>
                  <div className="ourRight-card">
                  <h5>CITIZENS</h5>
                    <ul>
                        <li>Social justice</li>
                        <li>Violation of right to vote</li>
                        <li>Terrorism-unlawful eviction</li>
                        <li>Violation of freedom of association</li>
                        <li>Violation of right to development</li>
                        <li>Violation of right to information</li>
                    </ul>
                  </div>
                  <div className="ourRight-card">
                  <h5>YOUTH</h5>
                    <ul>
                        <li>Denial of human rights education</li>
                        <li>Discrimination on the basis of race, sex, caste, religion and nationality</li>
                        <li>Unemployment, Ragging</li>
                        <li>Social media bullying & fraud</li>
                        <li>Violation of right to development</li>
                    </ul>
                  </div>

                </div>
            </div>
        </div>
    )
}

export default OurRights