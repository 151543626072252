import React from 'react'
import './Banner.css';
import Slider from './Slider';
import {Link, useNavigate} from 'react-router-dom'

const Banner = () => {

  const navigate = useNavigate();

  return (
    <div className="banner-Container">
      <div className='banner-hedding-wrapper'>
        <h1 className='banner-heading'>Aple Manavadhikar Foundation</h1>
        <h3 className='banner-subheading'>जनसामान्यांचा आधार, आपले मानवाधिकार</h3>
        <p className='banner-subinfo'>The purpose of this foundation is to promote our team, as well as to work on injustice in our society, corruption, women's workshops, jobs, agriculture, epidemics, other economical and social problems of across India.</p>
        <div className='banner-joinus'>
          <button className='AMFButton banner-btn' onClick={()=>navigate('/membership')}>Join Us</button>
          <a
            href="https://razorpay.com/payment-button/pl_HAzswnhQYzXkey/view/?utm_source=payment_button&utm_medium=button&utm_campaign=payment_button"
            type="submit"
            className="PaymentButton PaymentButton--dark svelte-ekc7fv"
            style={{ background: "rgb(7, 25, 249)" }}
          >
            <svg
              width={18}
              height={20}
              viewBox="0 0 18 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="svelte-ekc7fv"
            >
              <path
                d="M7.077 6.476l-.988 3.569 5.65-3.589-3.695 13.54 3.752.004 5.457-20L7.077 6.476z"
                fill="#fff"
                className="svelte-ekc7fv"
              ></path>
              <path
                d="M1.455 14.308L0 20h7.202L10.149 8.42l-8.694 5.887z"
                fill="#fff"
                className="svelte-ekc7fv"
              />
            </svg>{" "}
            <div className="PaymentButton-contents svelte-ekc7fv">
              <span className="PaymentButton-text svelte-ekc7fv">Donate Now</span>{" "}
              <div className="PaymentButton-securedBy svelte-ekc7fv">
                Secured by Razorpay
              </div>
            </div>
          </a>

        </div>
      </div>
      <div className='banner-slides'>
        <Slider />
      </div>
    </div>
  )
}

export default Banner