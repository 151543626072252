import React from 'react'
import './Notice.css';

const Notice = () => {
  return (
    <div className='notice'>
        <marquee behavior="scrool" direction="righttoleft">Important notice!!!  Aple Manvadhikar Foundation Website work is Done Register now & get your id card.</marquee>
    </div>
  )
}

export default Notice